var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "margin-top": "15px" } }, [
    !_vm.orgId
      ? _c(
          "div",
          [
            _c("Card", [
              _c("h5", [_vm._v("Basic Info")]),
              _vm._v(" "),
              _c("form", [
                _c(
                  "fieldset",
                  [
                    _c("InputText", {
                      attrs: { name: "name", label: "Name" },
                      model: {
                        value: _vm.org.name,
                        callback: function($$v) {
                          _vm.$set(_vm.org, "name", $$v)
                        },
                        expression: "org.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("InputText", {
                      attrs: { name: "website", label: "Website" },
                      model: {
                        value: _vm.org.website,
                        callback: function($$v) {
                          _vm.$set(_vm.org, "website", $$v)
                        },
                        expression: "org.website"
                      }
                    }),
                    _vm._v(" "),
                    _c("InputText", {
                      attrs: { name: "primary_email", label: "Billing Email" },
                      model: {
                        value: _vm.org.primary_email,
                        callback: function($$v) {
                          _vm.$set(_vm.org, "primary_email", $$v)
                        },
                        expression: "org.primary_email"
                      }
                    }),
                    _vm._v(" "),
                    _c("InputText", {
                      attrs: {
                        name: "setup_fee",
                        label: "Setup Fee",
                        "help-text":
                          "A setup fee invoice for this amount will be sent immediately to the billing email when the Org is created."
                      },
                      model: {
                        value: _vm.org.setup_fee_amount,
                        callback: function($$v) {
                          _vm.$set(_vm.org, "setup_fee_amount", $$v)
                        },
                        expression: "org.setup_fee_amount"
                      }
                    }),
                    _vm._v(" "),
                    _c("InputTextArea", {
                      attrs: { name: "about", label: "About" },
                      model: {
                        value: _vm.org.about,
                        callback: function($$v) {
                          _vm.$set(_vm.org, "about", $$v)
                        },
                        expression: "org.about"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "thumb-wrapper d-flex" },
                      [
                        _c("Thumb", {
                          staticClass: "item-image",
                          attrs: {
                            fullRounded: "",
                            src: _vm.logoSrc,
                            alt: "Company Logo"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "thumb-actions" },
                          [
                            _c("input", {
                              staticClass: "hide",
                              attrs: {
                                id: "fileUpload",
                                name: "picture",
                                type: "file",
                                src: "logoSrc"
                              },
                              on: { change: _vm.onFileChange }
                            }),
                            _vm._v(" "),
                            _c(
                              "BaseButton",
                              {
                                attrs: {
                                  type: "button",
                                  theme: "link",
                                  size: "sm"
                                },
                                on: { click: function() {} }
                              },
                              [_vm._v("Delete")]
                            ),
                            _vm._v(" "),
                            _c(
                              "BaseButton",
                              {
                                attrs: { type: "button", size: "sm" },
                                on: { click: _vm.onFileClick }
                              },
                              [_vm._v("Upload")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("fieldset", [
                  !_vm.obOrg
                    ? _c(
                        "div",
                        [
                          _c("Autocomplete", {
                            staticClass: "form-group material-input",
                            attrs: {
                              "input-class": "form-control",
                              resultsFormatter: _vm.acResultsFormatter,
                              resultsDisplay: _vm.acResultsDisplay,
                              source: _vm.obOrgAutocomplete
                            },
                            on: { selected: _vm.selectOBOrg }
                          })
                        ],
                        1
                      )
                    : _c("div", [
                        _c("div", { staticStyle: { "margin-top": "20px" } }, [
                          _c("img", {
                            staticStyle: {
                              height: "30px",
                              "margin-bottom": "-10px",
                              "margin-right": "10px"
                            },
                            attrs: {
                              src:
                                "https://ellisjm-private.s3.amazonaws.com/ob_logo.png"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.obOrg.name))]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-times-circle",
                            on: { click: _vm.clearOBOrg }
                          })
                        ])
                      ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "15px" } }, [
              _c(
                "button",
                { staticStyle: { float: "right" }, on: { click: _vm.create } },
                [_vm._v("Create")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { clear: "both" } })
            ])
          ],
          1
        )
      : _c(
          "div",
          [
            _c("Card", [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "24px",
                    "text-align": "center",
                    padding: "20px 0px"
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-check-circle",
                    staticStyle: { color: "limegreen", "margin-right": "10px" }
                  }),
                  _vm._v(
                    "You created a new Organization! Add brands and LOs\n        "
                  ),
                  _c("a", { attrs: { href: "/org-manager/" + this.orgId } }, [
                    _vm._v("HERE")
                  ])
                ]
              )
            ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1f70ca7b", { render: render, staticRenderFns: staticRenderFns })
  }
}
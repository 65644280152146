<doc>
    Subscription Dashboard Vue.js page
</doc>

<template>
  <div style="margin-top:15px;">
    <div v-if="!orgId">
      <Card>
        <h5>Basic Info</h5>
        <form>
          <fieldset>
            <InputText name="name" label="Name" v-model="org.name" />
            <InputText name="website" label="Website" v-model="org.website" />
            <InputText name="primary_email" label="Billing Email" v-model="org.primary_email" />
            <InputText name="setup_fee" label="Setup Fee" v-model="org.setup_fee_amount" help-text="A setup fee invoice for this amount will be sent immediately to the billing email when the Org is created."/>
            <InputTextArea name="about" label="About" v-model="org.about" />
            <div class="thumb-wrapper d-flex">
              <Thumb
                fullRounded
                :src="logoSrc"
                alt="Company Logo"
                class="item-image"
              />

              <div class="thumb-actions">
                <input
                  id="fileUpload"
                  name="picture"
                  type="file"
                  class="hide"
                  @change="onFileChange"
                  src="logoSrc"
                />
                <BaseButton
                  type="button"
                  v-on:click="() => {}"
                  theme="link"
                  size="sm"
                  >Delete</BaseButton
                >
                <BaseButton type="button" v-on:click="onFileClick" size="sm"
                  >Upload</BaseButton
                >
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div v-if="!obOrg">
              <Autocomplete
                class="form-group material-input"
                input-class="form-control"
                :resultsFormatter="acResultsFormatter"
                :resultsDisplay="acResultsDisplay"
                :source="obOrgAutocomplete"
                @selected="selectOBOrg"
              />
            </div>
            <div v-else>
              <div style="margin-top:20px;">
                <img
                  style="height:30px;margin-bottom:-10px;margin-right:10px;"
                  src="https://ellisjm-private.s3.amazonaws.com/ob_logo.png"
                />
                <span>{{ obOrg.name }}</span>
                <i @click="clearOBOrg" class="fas fa-times-circle"></i>
              </div>
            </div>
          </fieldset>
        </form>
      </Card>
      <div style="margin-top:15px;">
        <button style="float:right;" @click="create">Create</button>
        <div style="clear:both;"></div>
      </div>
    </div>
    <div v-else>
      <Card>
        <p style="font-size:24px;text-align:center;padding: 20px 0px;">
          <i
            style="color:limegreen;margin-right:10px;"
            class="fas fa-check-circle"
          ></i
          >You created a new Organization! Add brands and LOs
          <a :href="'/org-manager/' + this.orgId">HERE</a>
        </p>
      </Card>
    </div>
  </div>
</template>

<script>
import { InputText, InputTextArea } from "../components/Forms"
import Card from "../components/Card"
import Autocomplete from "vuejs-auto-complete"
import Thumb from "../components/Thumb"

export default {
  name: "NewCustomer",
  data() {
    return {
      org: {
        name: "",
        website: "",
        about: "",
        logo_fid: "",
        primary_email: "",
        listing_access: false,
        setup_fee_amount: "",
      },
      obOrg: null,
      logoSrc: "https://ellisjm-private.s3.amazonaws.com/image-regular.png",
      orgId: null,
      paymentLink: null
    }
  },
  components: {
    Card,
    InputText,
    InputTextArea,
    Autocomplete,
    Thumb
  },
  async mounted() {},
  updated() {},
  computed: {},
  methods: {
    obOrgAutocomplete(input) {
      //debugger;
      return "/api/v1/find-ob-org?name=" + input
    },
    getOBOrg(ob_info) {
      if (ob_info.biz_channel && ob_info.originator_id) {
        let org_id = this.$store.state.organization.orgID
        fetch(
          "/api/v1/find-ob-org?originator_id=" +
            this.member.originator_id +
            "&biz_channel=" +
            this.member.biz_channel +
            "&org_id=" +
            org_id,
          {
            method: "GET",
            credentials: "same-origin"
          }
        )
          .then(res => {
            return res.json()
          })
          .then(data => {
            this.obLO = data[0]
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    acResultsFormatter(data) {
      return data
    },
    acResultsDisplay(data) {
      return (
        `
                        <div class='resultWrapper'>
                            <div>
                                <span class="name">` +
        data.name +
        `</span>
                            </div>
                            <div class='details'>
                                <span style='font-weight:400;color:#aaa;'>` +
        data.index +
        `</span>
                            </div>
                        </div>
                    `
      )
    },
    selectOBOrg(data) {
      if (!data.selectedObject) {
        return
      }
      //debugger;
      //this.currentEditedItem.first_name=data.selectedObject.firstName;
      this.obOrg = data.selectedObject
      this.org.ob_biz_channel = data.selectedObject.index
    },
    clearOBOrg() {
      this.obOrg = null
    },
    onFileChange(e) {
      const file =
        e.target.files && e.target.files[0] ? e.target.files[0] : null

      if (file) {
        const data = new FormData()
        const reader = new FileReader()

        data.append("files[img]", e.target.files[0])
        data.append("bb_sid", window.SESSION_ID)

        reader.onload = e => {
          //$("#memberModal .item-image").attr("src", e.target.result)
          this.logoSrc = e.target.result
        }

        reader.readAsDataURL(file)

        // submitButton.attr('disabled', true);
        // loader.removeClass('hide');
        // text.html('Uploading');

        fetch("/bb-file-upload.json", {
          method: "POST",
          body: data,
          credentials: "same-origin"
        })
          .then(res => {
            return res.json()
          })
          .then(data => {
            this.org.logo_fid = data.message.fid
          })
          .catch(err => {
            console.error(err)
            // loader.addClass('hide');
          })
      }
    },
    onFileClick(e) {
      $("#fileUpload").click()
    },
    create() {
      console.log(this.org)
      if (!this.org || !this.org.name || !this.org.logo_fid || !this.org.primary_email) {
        BB.Toastr.error("Name, Logo and Billing Email required")
        return
      }
      fetch("/api/v1/new-customer", {
        method: "POST",
        body: JSON.stringify(this.org),
        credentials: "same-origin"
      })
        .then(res => {
          return res.json()
        })
        .then(data => {
          console.log(data)
          if (data.org_id) {
            this.orgId = data.org_id
            this.paymentLink = data.payment_link ? data.payment_link : null
          } else {
            BB.Toastr.error("Org not created")
          }
        })
        .catch(err => {
          console.error(err)
          // loader.addClass('hide');
        })
    }
  }
}
</script>

<style lang="scss">
h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.autocomplete {
  padding: 0px;
  box-shadow: none;
  &__icon {
    display: none;
  }
  &__inputs {
    padding: 0px;
  }
  &__box {
    padding: 0px;
    border: none;
  }
  &__results {
    margin-top: 1px;
    font-size: 16px;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 2px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid #ced4da;
    border-radius: 0;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .resultWrapper {
    .details {
      font-size: 90%;
      margin-top: 3px;
    }
  }
  &.material-input {
    margin-top: 25px;
    margin-bottom: 20px;
    position: relative;

    &.typed,
    &.focus {
      &::after {
        width: 100%;
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 1px;
    }

    &::before {
      background-color: #e9e9e9;
      z-index: 1;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    &::after {
      transform: translateY(-1px);
      width: 0;
      background-color: $primary;
      display: block;
      margin: 0 auto;
      position: relative;
      transition: width 0.2s cubic-bezier(0.55, 0, 0.55, 0.2);
      z-index: 2;
    }
  }
  @media screen and (max-width: 700px) {
    width: auto !important;
    right: 0 !important;
    top: 0 !important;
    left: 0 !important;
    margin: auto !important;
  }
}

.thumb-wrapper {
  margin-top: 15px;
  align-items: center;
  .company-image,
  .item-image {
    margin: 0 15px 0 0;
    width: 200px;
    object-fit: contain;
    &.full-rounded {
      border-radius: 5px;
      object-fit: contain;
    }
  }
  .item-image {
    height: 70px !important;
  }
  .company-image {
    height: auto;
  }
  .thumb-actions {
    .btn {
      font-size: 12px;
      display: block;
    }
  }
  .thumb-details {
    h6 {
      color: #5dbec7;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
}
.fa-times-circle {
  margin-left: 10px;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.payment-link-text{
  margin: 10px auto;
  font-size: medium;
  font-family: monospace;
}
</style>
